

































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import EmailComposer from '@/components/email-composer.vue';
import FinancialInformationModel from '@/models/crm/financial-information.model';
import FinancialInformationService from '@/services/crm/financial-information.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ClientModel from '@/models/crm/client.model';
import { FormHelper } from '@/utils/helpers/form-helper';
import EmailModel from '@/models/email.model';
import { IKeyValue } from '@/interfaces/key-value.interface';

@Component({
  components: {
    EmailComposer,
  },
})
export default class CrmEmailTituloView extends Vue {
  @inject(InjectionIdEnum.CrmFinancialInformationService)
  private financialInformationService!: FinancialInformationService;

  formIsValid = true;

  emailModel: EmailModel<IKeyValue<string | number>[]> = plainToClass(EmailModel, {
    cnpj: '',
    from: null,
    to: [],
    cc: [],
    subject: null,
    sendCopy: false,
    message: null,
    metadata: [],
  });

  rules = {
    from: [[ValidationRules.arrayLength, 1], ValidationRules.arrayOfEmails],
    to: [[ValidationRules.minArrayLength, 1], ValidationRules.arrayOfEmails],
    cc: [ValidationRules.arrayOfEmails],
    subject: [ValidationRules.required],
    message: [ValidationRules.required],
  };

  @Prop({ required: true })
  financialInfos!: FinancialInformationModel[];

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  created(): void {
    this.financialInfos.forEach((financialInfo) => {
      this.emailModel.metadata?.push({
        titulo: financialInfo.titulo,
        banco: financialInfo.banco,
        footer: this.getDefaultFooter(),
      });
    });

    if (this.userContactInfo.email) {
      this.emailModel.from = this.userContactInfo.email;
    }

    if (this.client.email) {
      this.emailModel.to = [this.client.email];
    }

    this.emailModel.subject = this.getDefaultSubject();
    this.emailModel.message = this.getDefaultMessage();
    this.emailModel.cnpj = this.client.cnpjCpf;
    // this.emailModel.sendCopy = this.getSendCopy();
  }

  async onSend(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue) && this.emailModel.metadata
    && this.emailModel.metadata.length > 0) {
      const loader = this.$loading.show({
        container: this.$refs.tituloEmailContainer,
        canCancel: false,
      });
      try {
        await this.financialInformationService.sendTituloByEmail(this.emailModel);

        this.$notify.success(this.$tc('crm.tituloEmailView.successfullySent', this.financialInfos.length));

        this.$emit('complete');
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
    if (this.financialInfos.length > 0 && (!this.emailModel.metadata
    || (this.emailModel.metadata && this.emailModel.metadata.length === 0))) {
      this.$notify.error(this.$t('crm.tituloEmailView.errorAwaitData'));
    }
    if (this.financialInfos.length === 0) {
      this.$notify.error(this.$t('crm.tituloEmailView.errorSelectData'));
    }
  }

  get files(): string[] {
    return (this.emailModel.metadata || []).map((item) => item.banco.toString()
      .concat(item.titulo.toString()));
  }

  private getDefaultSubject(): string {
    const financialInfoNumber = this.financialInfos.map((financialInfo) => `#${financialInfo.titulo}`).join(', ');
    return this.$tc('crm.tituloEmailView.defaultSubject', this.financialInfos.length, {
      company: this.userContactInfo.nomeEmpresa,
      financialInfoNumber,
    });
  }

  private getDefaultMessage(): string {
    let message = `${this.$t('crm.tituloEmailView.defaultMessage')}`;
    message += ` ${this.financialInfos.map((financialInfo) => `#${financialInfo.titulo}`).join(', ')}.`;
    message += '<br>';
    return message;
  }

  private getDefaultFooter(): string {
    let footer = '';
    if (this.userContactInfo.assinaturaEmail) {
      footer += `<br><br>${this.userContactInfo.assinaturaEmail.replaceAll(/(?:\r\n|\r|\n)/g, '<br>')}`;
    }
    return footer;
  }
}
